import React, { useEffect, useState, useReducer, useRef, useCallback } from 'react'
import { css } from '@emotion/css'
import { useAuth0 } from '../react-auth0-spa'
import { ulid } from 'ulid'
import LoadingPage from './LoadingPage'
import { TimetableData, ScheduleItem, ColorSetting } from '../types/TimetableData'
import update from 'immutability-helper'
import moment, { Moment } from 'moment'
import TimePicker from 'rc-time-picker'
import { Picker as EmojiPicker, BaseEmoji, PickerProps, EmojiData } from 'emoji-mart'
import { Link } from 'react-router-dom'
import { useTimetable } from "../queries/timetable"
import useDebounce from '../utils/useDebounce'
import shallowEqual from '../utils/shallowEqual'
import classNames from 'classnames'
import themeClassesForColorSettings from '../utils/themeClassesForColorSettings'

const INITIAL_SCHEDULE: ScheduleItem[] = [
  {
    id: ulid(),
    start: 0,
    msg: "ねるじかん",
    emoji: "🛌🏼",
    colorSetting: "light",
  },
  {
    id: ulid(),
    start: 7.0,
    msg: "あさごはん",
    emoji: "🧇🍳🥞",
    colorSetting: "light",
  },
  {
    id: ulid(),
    start: 8.0,
    msg: "あそぶじかん",
    emoji: "🧒🏻👦🏻",
    colorSetting: "light",
  },
  {
    id: ulid(),
    start: 11.5,
    msg: "ひるごはんたいむ",
    emoji: "🍝",
    colorSetting: "light",
  },
  {
    id: ulid(),
    start: 12.5,
    msg: "あそぶじかん",
    emoji: "🤡😴",
    colorSetting: "light",
  },
  {
    id: ulid(),
    start: 15.0,
    msg: "おやつたいむ",
    emoji: "🍭",
    colorSetting: "light",
  },
  {
    id: ulid(),
    start: 15.5,
    msg: "あそぶじかん",
    emoji: "🤾",
    colorSetting: "light",
  },
  {
    id: ulid(),
    start: 17.0,
    msg: "かたづけ",
    emoji: "🧹",
    colorSetting: "light",
  },
  {
    id: ulid(),
    start: 17.5,
    msg: "おふろにはいる",
    emoji: "🛀",
    colorSetting: "light",
  },
  {
    id: ulid(),
    start: 18,
    msg: "ゆうしょく",
    emoji: "🍽️",
    colorSetting: "light",
  },
  {
    id: ulid(),
    start: 19.25,
    msg: "しずかにあそぶじかん",
    emoji: "🎴",
    colorSetting: "light",
  },
  {
    id: ulid(),
    start: 20.5,
    msg: "ねるじゅんび。はみがきしてほんをえらぼう",
    emoji: "📕",
    colorSetting: "light",
  },
  {
    id: ulid(),
    start: 21,
    msg: "ねるじかん",
    emoji: "🌠",
    colorSetting: "light",
  }
]

interface ScheduleRowCellProps {
  item: ScheduleItem
  updateItem: any
  addItem: any
  removeItem: any
}

const _formatTime = (input: number) => {
  const hourPart = Math.floor(input)
  const minutePart = input - hourPart
  const minutes = "" + Math.round(minutePart * 60)
  return `${("" + hourPart).padStart(2, "0")}:${minutes.padStart(2, "0")}`
}

interface FloatingEmojiPickerProps {
  emojiPickerProps: PickerProps
  visible: boolean
  dismiss: () => void
}
const FloatingEmojiPicker: React.FC<FloatingEmojiPickerProps> = (props) => {
  const { dismiss } = props
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (ev: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(ev.target as Node)) {
        dismiss()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef, dismiss])

  if (!props.visible) return null

  return <div ref={wrapperRef} className={css`
    position: absolute;
    z-index: 15;
  `}>
    <EmojiPicker {...props.emojiPickerProps} />
  </div>
}

const ScheduleRowCell: React.FC<ScheduleRowCellProps> = ({item, updateItem, addItem, removeItem}) => {
  const itemRef = useRef(item)
  const [ newMsg, setNewMsg ] = useState<string>(item.msg)
  const debouncedMsg = useDebounce(newMsg, 1000)
  const [ newEmoji, setNewEmoji ] = useState<string>(item.emoji || "")
  const debouncedEmoji = useDebounce(newEmoji, 1000)

  const [ colorSetting, setColorSettings ] = useState<ColorSetting>(item.colorSetting || "light")

  useEffect(() => {
    const newItem = {
      ...itemRef.current,
      msg: debouncedMsg,
      emoji: debouncedEmoji,
      colorSetting,
    }
    if (!shallowEqual(newItem, itemRef.current)) {
      updateItem(newItem)
    }
  }, [ updateItem, debouncedMsg, debouncedEmoji, colorSetting ])

  const [ emojiPickerVisible, setEmojiPickerVisible ] = useState<boolean>(false)

  const onTimeChange = useCallback(
    (newValue: Moment) => {
      const hour = newValue.hour()
      const minute = newValue.minute()
      const newTime = hour + (minute / 60)
      updateItem({
        ...itemRef.current,
        start: newTime
      })
    },
    [ updateItem, itemRef ]
  )

  const onMsgChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => setNewMsg(ev.currentTarget.value),
    [ setNewMsg ]
  )

  const onEmojiSelect = useCallback(
    (emoji: EmojiData) => {
      const baseEmoji = emoji as BaseEmoji
      if (baseEmoji.native) {
        setNewEmoji(prev => prev + baseEmoji.native)
      }
    },
    [ setNewEmoji ]
  )

  const themeClasses = themeClassesForColorSettings(colorSetting)

  return <>
    <div className={classNames("list-group-item py-3 p-relative", themeClasses)}>
      <div className="d-flex">
        <div className="mr-2">
          <TimePicker
            defaultValue={moment(`2000-01-01T${_formatTime(item.start)}:00+09:00`)}
            showSecond={false}
            minuteStep={15}
            allowEmpty={false}
            onChange={onTimeChange}
            className="form-control"
          />
        </div>
        <input
          type="text"
          value={newMsg}
          onChange={onMsgChange}
          className="form-control mr-2"
        />
        <div className={"mr-2 " + css`
          position: relative;
        `}>
          <input
            className="form-control"
            type="text"
            value={newEmoji}
            onChange={ev => setNewEmoji(ev.currentTarget.value)}
            onFocus={ev => {
              setEmojiPickerVisible(true)
            }}
          />
          <FloatingEmojiPicker
            visible={emojiPickerVisible}
            dismiss={() => setEmojiPickerVisible(false)}
            emojiPickerProps={{
              set: "twitter",
              onSelect: onEmojiSelect,
              title: "絵文字を選ぼう！"
            }}
          />
        </div>

        <div className="btn-group btn-group-toggle mr-2">
          <label className={classNames("btn btn-light", { "active": colorSetting === "light" })}>
            <input
              type="radio"
              checked={colorSetting === "light"}
              onChange={ev => ev.currentTarget.checked && setColorSettings("light")}
            /> 白
          </label>

          <label className={classNames("btn btn-primary", { "active": colorSetting === "primary" })}>
            <input
              type="radio"
              checked={colorSetting === "primary"}
              onChange={ev => ev.currentTarget.checked && setColorSettings("primary")}
            /> 青
          </label>
        </div>

        <button
          className="btn btn-danger btn-sm"
          type="button"
          onClick={ev => {
            ev.preventDefault()
            removeItem(item)
          }}
        >
          &times;
        </button>
      </div>
    </div>
    <div className={css`
      display: flex;
      margin-top: -15.5px;
      margin-bottom: -15.5px;
      z-index: 10;
    `}>
      <button
        type="button"
        onClick={() => addItem(item)}
        className={css`
          border: 1px solid #CCC;
          border-radius: 25px;
          width: 30px;
          height: 30px;
          vertical-align: middle;
          line-height: 1px;
          margin-left: auto;
          margin-right: 1.25rem;
        `}
      >
        +
      </button>
    </div>
  </>
}

type InnerSettingsAction =
  { type: 'updateItem', id: string, item: ScheduleItem } |
  { type: 'addItem', afterId: string, initialTime: number } |
  { type: 'removeItem', id: string }
type InnerSettingsState = TimetableData & { mutation: any }
type InnerSettingsReducer = (state: InnerSettingsState, action: InnerSettingsAction) => InnerSettingsState

const innerSettingsReducer: InnerSettingsReducer = (state, action) => {
  let newState: InnerSettingsState = state
  switch (action.type) {
    case 'updateItem': {
      const idx = state.schedule.findIndex(itm => itm.id === action.id)
      newState = update(state, {
        schedule: {
          $splice: [[idx, 1, action.item]]
        }
      })
      newState.schedule.sort( (a, b) => a.start - b.start )
      state.mutation({ schedule: newState.schedule })
      return newState
    }
    case 'addItem': {
      const atIndex = state.schedule.findIndex(itm => itm.id === action.afterId) + 1
      newState = update(state, {
        schedule: {
          $splice: [[atIndex, 0, { id: ulid(), start: action.initialTime, msg: "" }]]
        }
      })
      state.mutation({ schedule: newState.schedule })
      return newState
    }
    case 'removeItem': {
      const idx = state.schedule.findIndex(itm => itm.id === action.id)
      newState = update(state, {
        schedule: {
          $splice: [[idx, 1]]
        }
      })
      state.mutation({ schedule: newState.schedule })
      return newState
    }
    default:
      throw new Error()
  }
}

type InnerSettingsInitArg = {initialData: TimetableData | null, mutation: any}
type InnerSettingsInit = (setup: InnerSettingsInitArg) => InnerSettingsState
const innerSettingsInit: InnerSettingsInit = ({initialData, mutation}) => {
  if (initialData) {
    return { mutation, schedule: initialData.schedule}
  } else {
    return { mutation, schedule: INITIAL_SCHEDULE }
  }
}

interface SettingsInnerProps {
  initialData: TimetableData | null
  updateTimetable: (newTT: TimetableData) => Promise<void>
}

const SettingsInner: React.FC<SettingsInnerProps> = ({initialData, updateTimetable}) => {
  const [ lastUpdateClasses, setLastUpdateClasses ] = useState<string>("")

  const updateWithNotification = useCallback<any>(
    (options?: any) => {
      setLastUpdateClasses(css`
        opacity: 1;
      `)
      return updateTimetable(options)
    },
    [ updateTimetable ]
  )

  useEffect(() => {
    if (lastUpdateClasses !== "") {
      window.setTimeout(() => {
        setLastUpdateClasses("")
      }, 3_000)
    }
  }, [ lastUpdateClasses, setLastUpdateClasses ])

  const [ data, dispatch ] = useReducer<
    InnerSettingsReducer, InnerSettingsInitArg
  >(innerSettingsReducer, { initialData, mutation: updateWithNotification }, innerSettingsInit)

  const updateItem = useCallback(
    (item: ScheduleItem) => {
      dispatch({ type: 'updateItem', id: item.id, item })
    },
    [ dispatch ]
  )

  const addItem = useCallback(
    (item: ScheduleItem) => {
      dispatch({ type: 'addItem', afterId: item.id, initialTime: item.start + 0.25 })
    },
    [ dispatch ]
  )

  const removeItem = useCallback(
    (item: ScheduleItem) => {
      dispatch({ type: 'removeItem', id: item.id })
    },
    [ dispatch ]
  )

  return <div className="container">
    <div className="row justify-content-center">
      <div className="col-md-10 my-4">
        <div className={"mb-1 p-2 border-bottom " + css`
          position: sticky;
          top: 0px;
          z-index: 100;
          background: white;
        `}>
          <div className="d-flex">
            <Link to="/" className="btn btn-outline-secondary mr-auto">
              ← トップに戻る
            </Link>
            <span className={"btn btn-text " + css`
              opacity: 0;
              transition: opacity 300ms ease-in-out;
            ` + lastUpdateClasses}>
              保存しました
            </span>
            <Link to="/view" className="btn btn-outline-secondary ml-2">
              表示する →
            </Link>
          </div>
        </div>
        <div className="list-group">
          { data.schedule.map((item) => (
            <ScheduleRowCell
              item={item}
              updateItem={updateItem}
              addItem={addItem}
              removeItem={removeItem}
              key={item.id}
            />
          )) }
        </div>
      </div>
    </div>
  </div>
}

const SettingsWrapper: React.FC = () => {
  const { timetable, getTimetable, updateTimetable, loading } = useTimetable()

  useEffect(() => {
    getTimetable()
  }, [ getTimetable ])

  if (loading) return <LoadingPage />

  return <SettingsInner
    initialData={timetable}
    updateTimetable={updateTimetable}
  />
}

const SettingsPage: React.FC = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0()
  useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect({ appState: { targetUrl: "/settings" } })
    }
  }, [ isAuthenticated, loginWithRedirect ])

  if (!isAuthenticated) return null

  return <SettingsWrapper />
}

export default SettingsPage
