import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import { useAuth0 } from "./react-auth0-spa"
import HomePage from './pages/HomePage'
import ViewPage from './pages/ViewPage'
import SettingsPage from './pages/SettingsPage'
import LoadingPage from './pages/LoadingPage'
import PrivacyPolicyPage from './pages/PrivacyPolicyPage'
import TermsOfServicePage from './pages/TermsOfServicePage'
import { useTracker } from './utils/useTracker'
import AboutPage from './pages/AboutPage'

const AppRoutes: React.FC = () => {
  useTracker()

  return <Switch>
    <Route path="/settings">
      <SettingsPage />
    </Route>

    <Route path="/view">
      <ViewPage />
    </Route>

    <Route path="/privacy-policy">
      <PrivacyPolicyPage />
    </Route>

    <Route path="/terms-of-service">
      <TermsOfServicePage />
    </Route>

    <Route path="/about">
      <AboutPage />
    </Route>

    <Route path="/" exact>
      <HomePage />
    </Route>
  </Switch>
}

const App = () => {
  const { loading } = useAuth0()

  if (loading) {
    return <LoadingPage />
  }

  return (
    <Router>
      <AppRoutes />
    </Router>
  )
}

export default App
