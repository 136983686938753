import { ColorSetting } from "../types/TimetableData"

const themeClassesForColorSettings = (colorSettings: ColorSetting | undefined) => {
  switch (colorSettings) {
    case "light":
      return "bg-light text-dark"
    case "primary":
      return "bg-primary text-light"
  }
  return ""
}

export default themeClassesForColorSettings
