import React, { useEffect, useMemo, useState } from "react"
import { useTimetable } from "../queries/timetable"
import LoadingPage from "./LoadingPage"
import { useAuth0 } from "../react-auth0-spa"
import { TimetableData, ScheduleItem } from "../types/TimetableData"
import moment from 'moment'
import emoji from 'react-easy-emoji'
import classNames from 'classnames'
import themeClassesForColorSettings from "../utils/themeClassesForColorSettings"
import { getScheduleForNow, getNextSchedule, minutesUntilSchedule } from "../utils/scheduling"
import useSound from "use-sound"
import bellSfx from '../sounds/ding3x.mp3'

const ViewInner: React.FC<{data: TimetableData}> = ({data}) => {
  const [ playBell ] = useSound(bellSfx, {})
  const [ now, setNow ] = useState(moment())

  const [scheduleState, setScheduleState] = useState<{
    current: ScheduleItem, next: ScheduleItem
  }>({
    current: getScheduleForNow(data.schedule, now),
    next: getNextSchedule(data.schedule, now)
  })

  useEffect(() => {
    let timer: number, shouldRun = true
    const runTick = () => {
      if (!shouldRun) return
      const now = moment()
      setNow(now)
      setScheduleState(ss => {
        const newCurrent = getScheduleForNow(data.schedule, now)
        if (newCurrent.id !== ss.current.id) {
          playBell()
          return {
            current: newCurrent,
            next: getNextSchedule(data.schedule, now)
          }
        }
        return ss
      })
      timer = window.setTimeout(runTick, 500)
    }
    runTick()

    return () => {
      shouldRun = false
      if (timer) {
        window.clearTimeout(timer)
      }
    }
  }, [ data.schedule, setNow, setScheduleState, playBell ])

  const schedule = scheduleState.current
  const nextSchedule = scheduleState.next
  const themeClasses = themeClassesForColorSettings(schedule.colorSetting)

  return (
    <div className={classNames("w-100 vh-100 d-flex justify-content-center align-items-center", themeClasses)}>
      <div className="text-center py-4">
        <h1 className="display-3">{schedule.msg}</h1>

        <h2>
          {now.hour() >= 11 ? 'ごご' : 'あさ' }
          &nbsp;
          {now.format('h じ m ふん')}
        </h2>

        <h3>つぎは「<span>{nextSchedule.msg}</span>」だよ</h3>
        <h3>あと {minutesUntilSchedule(now, nextSchedule) } ふんだよ</h3>

        { schedule.parsedEmoji &&
          <p className="display-3">{schedule.parsedEmoji}</p>
        }
      </div>
    </div>
  );
}

const ViewWrapper: React.FC = () => {
  // const { loading, error, data } = useQuery(QUERY_TIMETABLE, {
  //   pollInterval: 60_000 // poll for new settings every minute
  // })
  const { timetable, getTimetable, loading } = useTimetable()

  useEffect(() => {
    let timeout: number
    const refresher = async () => {
      await getTimetable()
      timeout = window.setTimeout(refresher, 50_000)
    }
    refresher()
    return () => {
      if (timeout) {
        window.clearInterval(timeout)
      }
    }
  }, [getTimetable])

  const parsedData = useMemo(() => {
    if (!timetable) return
    for (let i = 0; i < timetable.schedule.length; i++) {
      const sch = timetable.schedule[i]
      if (sch.emoji) {
        sch.parsedEmoji = emoji(sch.emoji, {
          baseUrl: 'https://twemoji.maxcdn.com/2/svg/',
          ext: '.svg',
          size: ''
        })
      }
    }
    return timetable
  }, [timetable])

  if (loading) return <LoadingPage />
  if (!parsedData) return null

  return <ViewInner data={parsedData} />
}

const ViewPage: React.FC = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0()
  useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect({ appState: { targetUrl: "/settings" } })
    }
  }, [ isAuthenticated, loginWithRedirect ])

  if (!isAuthenticated) return null

  return <ViewWrapper />
}

export default ViewPage
