import axios from "axios"
import { useCallback, useState } from 'react'
import { useAuth0 } from '../react-auth0-spa'
import { TimetableData } from '../types/TimetableData'

interface GetTimetableResponseFailure {
  error: true
}

interface GetTimetableResponseSuccess {
  error: false
  timetable: TimetableData | null
}

type GetTimetableResponse = GetTimetableResponseFailure | GetTimetableResponseSuccess

interface UpdateTimetableResponse {
  error: boolean
}

export const useTimetable = () => {
  const { getTokenSilently } = useAuth0()

  const [ loading, setLoading ] = useState<boolean>(true)
  const [ timetable, setTimetable ] = useState<TimetableData | null>(null)

  const getTimetable = useCallback(async () => {
    const token = await getTokenSilently()
    if (!token) {
      console.error("Not authenticated yet.")
      return
    }
    const resp = await axios.get<GetTimetableResponse>(`/apiv2/timetable`, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
    if (resp.data.error === false) {
      setTimetable(resp.data.timetable)
    } else {
      console.log("API Error", resp.data)
    }
    setLoading(false)
  }, [ getTokenSilently, setTimetable, setLoading ])

  const updateTimetable = useCallback(async (timetable: TimetableData) => {
    const token = await getTokenSilently()
    if (!token) {
      console.error("Not authenticated yet.")
      return
    }
    const resp = await axios.patch<UpdateTimetableResponse>(`/apiv2/timetable`, {
      timetable
    }, {
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    if (resp.data.error !== false) {
      console.log("API Error", resp.data)
    }
  }, [ getTokenSilently ])

  return {
    timetable,
    getTimetable,
    updateTimetable,
    loading
  }
}
