import { useEffect } from "react"
import ReactGA, { FieldsObject } from "react-ga"
import { useLocation } from "react-router-dom"

if (process.env.NODE_ENV === 'production')
  ReactGA.initialize("UA-133278638-4")
else
  ReactGA.initialize("TEST", { debug: true, testMode: true })

const trackPage = (page: string, options?: FieldsObject) => {
  ReactGA.set({ page, ...options })
  ReactGA.pageview(page)
}

export const useTracker = () => {
  const location = useLocation()
  useEffect(() => {
    trackPage(location.pathname)
  }, [location.pathname])
}
