import { ScheduleItem } from "../types/TimetableData"

const scheduleIndexForNow = (schedule: ScheduleItem[], now: moment.Moment) => {
  let now_f = now.hour() + (now.minute() / 60)
  for (let i = schedule.length - 1; i >= 0; i--) {
    let s = schedule[i]
    if (s.start <= now_f) {
      return i
    }
  }
  return undefined
}

export const getScheduleForNow = (schedule: ScheduleItem[], now: moment.Moment) => {
  const idx = scheduleIndexForNow(schedule, now)
  if (typeof idx !== 'undefined') {
    return schedule[idx]
  }
  // Default to returning last item
  return schedule[schedule.length - 1]
}

export const getNextSchedule = (schedule: ScheduleItem[], now: moment.Moment) => {
  const idx = scheduleIndexForNow(schedule, now)
  if (typeof idx !== 'undefined') {
    const nextIdx = idx + 1
    if (nextIdx >= schedule.length)
      return schedule[0]
    else
      return schedule[idx + 1]
  }
  // Default to returning first item.
  return schedule[0]
}

export const minutesUntilSchedule = (now: moment.Moment, sch: ScheduleItem) => {
  let now_f = now.hour() + (now.minute() / 60)
  let hoursUntilSch = sch.start - now_f
  if (hoursUntilSch < 0) {
    hoursUntilSch += 24.0
  }

  return Math.round(hoursUntilSch * 60)
}
