import React from 'react'
import ReactDOM from 'react-dom'
import 'rc-time-picker/assets/index.css'
import 'emoji-mart/css/emoji-mart.css'
import './index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'

import * as Sentry from '@sentry/browser'

import { Auth0Provider } from "./react-auth0-spa"
import config from "./auth_config.json"
import history from "./utils/history"
// import AuthenticatedApolloProvider from './AuthenticatedApolloProvider'
import ErrorBoundary from './ErrorBoundary'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://3a8022307c69458db765f886d41a6693@o249433.ingest.sentry.io/5230583',
    release: process.env.REACT_APP_RELEASE_VERSION,
  })
}

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState: any) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Auth0Provider
        clientOptions={{
          domain: config.domain,
          client_id: config.clientId,
          redirect_uri: `${window.location.origin}/authorize`,
          cacheLocation: "localstorage" ,
          audience: "https://jikanwari.kbys.me/api2",
        }}
        onRedirectCallback={onRedirectCallback}
      >
        <App />
      </Auth0Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
