import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth0 } from '../react-auth0-spa'
import logo from '../logo512.png'

const PreauthenticatedHomePageSection: React.FC = () => (
  <div className="mb-4">
    <div className="row justify-content-center">
      <div className="col-6">
        <img className="w-100 h-auto" src={logo} alt="おうち時間のロゴ" />
      </div>
    </div>
    <h1>おうち時間割</h1>
    <p className="text-left">
      「おうち時間割」はおうち時間を整理するためのシンプルなアプリ。まずはアカウントを作って「設定・登録」のボタンを押してください。そこから
      時間割を作れます。時間割の設定が終わりましたら、表示する端末にこのアプリを読み込んで、ログインをした後に「表示する」のボタンを押すと、現在の時間が表示されます。
    </p>

    <small>
      <a href="https://keita.blog/2020/05/21/%e5%ae%b6%e5%ba%ad%e6%96%99%e7%90%86%e3%81%ae%e3%82%88%e3%81%86%e3%81%aa%e3%83%97%e3%83%ad%e3%83%80%e3%82%af%e3%83%88%e9%96%8b%e7%99%ba/">このサービスを作ったきっかけについてはこちら</a>
    </small>
  </div>
)

const HomePage: React.FC = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0()

  return <div className="container h-100">
    <div className="row min-vh-100 justify-content-center align-items-center">
      <div className="col-lg-4 col-md-6 col-sm-8 text-center">


        { !isAuthenticated && <>
          <PreauthenticatedHomePageSection />

          <button
            className="btn btn-primary btn-lg btn-block"
            type="button"
            onClick={() => {
              loginWithRedirect({ appState: { targetUrl: "/" } })
            }}
          >
            <ruby>新規登録<rt>しんきとうろく</rt></ruby>・ログイン
          </button>
        </>}

        { isAuthenticated && <>
          <Link
            to="/settings"
            className="btn btn-primary btn-lg btn-block mb-4 pt-3"
          >
            <ruby>設定<rt>せってい</rt></ruby>・<ruby>登録<rt>とうろく</rt></ruby>する
          </Link>

          <Link
            to="/view"
            className="btn btn-primary btn-lg btn-block pt-3"
          >
            <ruby>表示<rt>ひょうじ</rt></ruby>する
          </Link>
        </>}

        { isAuthenticated && (
          <button
            className="btn btn-outline-danger btn-sm mt-4"
            type="button"
            onClick={() => logout({ returnTo: process.env.REACT_APP_HOSTNAME })}
          >
            ログアウト
          </button>
        )}

        <div className="mt-4">
          <small>
            <Link to="/privacy-policy">プライバシーポリシー</Link>
            &nbsp;・&nbsp;
            <Link to="/terms-of-service">利用規約</Link>
            <br />
            <Link to="/about">このアプリについて</Link>
          </small>
        </div>
      </div>
    </div>
  </div>
}

export default HomePage
