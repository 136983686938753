import React from "react"
import { Link } from "react-router-dom"
import moment from "moment"

const AboutPage: React.FC = () => (
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-10 py-4">
        <div className="text-center">
          <div className="mb-4">
            <Link to="/" className="btn btn-outline-primary">トップに戻る</Link>
          </div>
          <h1 className="display-4">このアプリについて</h1>
        </div>
        <hr />
        <p>
          このアプリはCOVID-19パンデミック最中、保育園が長期間臨時休園になったときに作られました。長期間、子供の世話をしながら仕事を両立することをちょっとでも助けるために、という思いが起因でした。詳しくは<a href="https://keita.blog/2020/05/21/%e5%ae%b6%e5%ba%ad%e6%96%99%e7%90%86%e3%81%ae%e3%82%88%e3%81%86%e3%81%aa%e3%83%97%e3%83%ad%e3%83%80%e3%82%af%e3%83%88%e9%96%8b%e7%99%ba/">ブログ記事をお読みください</a>。
        </p>

        <h2>クレジット</h2>

        <p>
          このアプリのフロントエンドは <a href="https://reactjs.org/">React</a> と <a href="https://getbootstrap.com/">Bootstrap</a> を使って作られています。下記に主要ライブラリをリストしています。
        </p>

        <ul>
          <li><a href="https://www.apollographql.com/">Apollo GraphQL</a> - GraphQL のクエリーを実行したり、Reactとの連携のために使われています</li>
          <li><a href="https://github.com/missive/emoji-mart">Emoji Mart</a> - 絵文字の入力補助として使われています</li>
          <li><a href="https://momentjs.com/">Moment.js</a> - 時間計算や表示のために使われています</li>
          <li><a href="https://github.com/react-component/time-picker">React TimePicker</a> - 設定画面で時間入力補助として使われています</li>
          <li><a href="https://github.com/joshwcomeau/use-sound">useSound</a> - React Hook でサウンドエフェクトを再生するために使われています</li>
        </ul>

        <p>
          バックエンドは <a href="https://www.auth0.com/">Auth0</a> を認証のために使って、 AWS Lambda 、 API Gateway 、 DynamoDB を使用しております。
        </p>

        <h5>その他</h5>

        <p>
          チャイム音は <a href="https://otologic.jp/">OtoLogic</a> が <a href="https://otologic.jp/">CC BY 4.0</a> のライセンスで公開されている素材を利用されています。
        </p>

        <p>
          このアプリは © {moment().format("YYYY")} <a href="https://kotobamedia.com">KotobaMedia</a> です。
        </p>
      </div>
    </div>
  </div>
)

export default AboutPage
