import React from 'react'

const LoadingPage: React.FC = () => {
  return <div className="container h-100">
    <div className="row min-vh-100 justify-content-center align-items-center">
      <div className="col text-center">
        <h1>ちょっと<ruby>待<rt>ま</rt></ruby>ってね...</h1>
      </div>
    </div>
  </div>
}

export default LoadingPage
