import React, { useState, useEffect, useContext, useMemo } from "react"
import createAuth0Client,
  {
    Auth0ClientOptions,
    Auth0Client,
    PopupLoginOptions,
    RedirectLoginResult,
    getIdTokenClaimsOptions,
    IdToken,
    RedirectLoginOptions,
    GetTokenSilentlyOptions,
    GetTokenWithPopupOptions,
    LogoutOptions,
    User,
  } from "@auth0/auth0-spa-js"

type Auth0RedirectCallback<T = any> = (appState?: T) => void

interface Auth0ContextContents {
  isAuthenticated: boolean
  user: any
  loading: boolean
  popupOpen: boolean
  loginWithPopup(options: PopupLoginOptions): Promise<void>
  handleRedirectCallback(): Promise<RedirectLoginResult>
  getIdTokenClaims(o?: getIdTokenClaimsOptions): Promise<IdToken>
  loginWithRedirect(o: RedirectLoginOptions): Promise<void>
  getTokenSilently(o?: GetTokenSilentlyOptions): Promise<string | undefined>
  getTokenWithPopup(o?: GetTokenWithPopupOptions): Promise<string | undefined>
  logout(o?: LogoutOptions): void
}
interface Auth0ProviderOptions<T = any> {
  children: React.ReactElement
  onRedirectCallback?: Auth0RedirectCallback<T>
  clientOptions: Auth0ClientOptions
}

const DEFAULT_REDIRECT_CALLBACK: Auth0RedirectCallback = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

export const Auth0Context = React.createContext<Auth0ContextContents | null>(null)
export const useAuth0 = () => useContext(Auth0Context)!
export const Auth0Provider: React.FC<Auth0ProviderOptions> = (props) => {
  const {
    children,
    clientOptions
  } = props
  const onRedirectCallback = props.onRedirectCallback || DEFAULT_REDIRECT_CALLBACK

  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [user, setUser] = useState<User | undefined>()
  const [auth0Client, setAuth0] = useState<Auth0Client>()
  const [loading, setLoading] = useState(true)
  const [popupOpen, setPopupOpen] = useState(false)

  useEffect(() => {
    (async () => {
      const auth0FromHook = await createAuth0Client(clientOptions)
      setAuth0(auth0FromHook)

      if (window.location.search.includes("code=")) {
        const { appState } = await auth0FromHook.handleRedirectCallback()
        onRedirectCallback(appState)
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated()

      setIsAuthenticated(isAuthenticated)

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser()
        setUser(user)
      }

      setLoading(false)
    })();
  }, [ clientOptions, onRedirectCallback ])

  const loginWithPopup = async (o: PopupLoginOptions) => {
    setPopupOpen(true)
    try {
      await auth0Client!.loginWithPopup(o)
    } catch (error) {
      console.error(error)
    } finally {
      setPopupOpen(false)
    }
    const user = await auth0Client!.getUser()
    setUser(user)
    setIsAuthenticated(true)
  }

  const handleRedirectCallback = async () => {
    setLoading(true)
    const result = await auth0Client!.handleRedirectCallback()
    const user = await auth0Client!.getUser()
    setLoading(false)
    setIsAuthenticated(true)
    setUser(user)
    return result
  }

  const getIdTokenClaims = useMemo(() => {
    return (o: getIdTokenClaimsOptions | undefined) =>
      auth0Client!.getIdTokenClaims(o)
  }, [auth0Client])

  const loginWithRedirect = useMemo(() => {
    return (o: RedirectLoginOptions) =>
      auth0Client!.loginWithRedirect(o)
  }, [auth0Client])

  const getTokenSilently = useMemo(() => {
    return (o: GetTokenSilentlyOptions | undefined) =>
      auth0Client!.getTokenSilently(o)
  }, [auth0Client])

  const getTokenWithPopup = useMemo(() => {
    return (o: GetTokenWithPopupOptions | undefined) =>
      auth0Client!.getTokenWithPopup(o)
  }, [auth0Client])

  const logout = useMemo(() => {
    return (o: LogoutOptions | undefined) => auth0Client!.logout(o)
  }, [auth0Client])

  if (!auth0Client) {
    return null
  }

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims,
        loginWithRedirect,
        getTokenSilently,
        getTokenWithPopup,
        logout
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}
