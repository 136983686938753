import React from "react"
import * as Sentry from '@sentry/browser'

interface ErrorBoundaryProps {}
interface ErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: any) {
    // You can also log the error to an error reporting service
    this.setState({ hasError: true })
    Sentry.withScope(scope => {
      scope.setExtra("errorInfo", errorInfo)
      Sentry.captureException(error)
    })
  }

  render() {
    if (this.state.hasError) {
      return <div className="w-100 vh-100 d-flex justify-content-center align-items-center">
        <div className="text-center p-4">
          <h1>申し訳ありません。予期せぬエラーが発生しました。時間を開けてからこのページを再読み込みをしてください。</h1>
        </div>
      </div>
    }

    return this.props.children
  }
}

export default ErrorBoundary
